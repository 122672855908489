import React, { lazy, Suspense, useEffect } from 'react'
import { RouteObject } from 'react-router'
import type { RouteMatch } from 'react-router'
import type { DehydratedState } from 'react-query'
import type { StaticRouterProps } from 'react-router-dom/server'
import {
  getCustomerInfo,
  updateUserInfo,
  fetchCommonData
} from '../../../ind/api/home/store/slice/appSlice'
import './assets/css/reset.css'
import './assets/scss/common.scss'
import './assets/fonts/iconfont/iconfont.css'
import './assets/scss/antcover.scss'
import { NoSSR } from '@utils/SSR'
import { Button, ConfigProvider, theme } from 'antd'

const ErrorFound = lazy(() => import('@ui-pc/ErrorPage'))
const NotFound = lazy(() => import('@ui-pc/NotFound'))
const LoginModal = lazy(() => import('./components/LoginModal'))

const {
  QueryClient,
  useQueryErrorResetBoundary,
  Hydrate,
  QueryClientProvider
} = await import('react-query')

const { ErrorBoundary } = await import('react-error-boundary')
const { HelmetProvider } = await import('react-helmet-async')
const { StaticRouter } = await import('react-router-dom/server')
const { Routes, Route } = await import('react-router')
const { BrowserRouter, createBrowserRouter, RouterProvider } = await import(
  'react-router-dom'
)
const { Provider } = await import('react-redux')

const routes = await import('./routes').then((res) => res.default)

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true
    }
  }
})

type AppType = {
  store: any
  isServer: boolean
  matchedRoutes?: RouteMatch[]
  location?: StaticRouterProps['location']
  dehydratedState?: DehydratedState
  preloadedState: { [x: string]: any }
  helmetContext: any
}

const App = ({
  store,
  isServer,
  location,
  preloadedState,
  dehydratedState,
  helmetContext
}: AppType) => {
  const { reset } = useQueryErrorResetBoundary()

  // 递归路由
  const mapRenderRoutes = (routes: RouteObject[]): React.ReactNode => {
    //console.log('routes', routes)
    return routes.map((route) => {
      return (
        //@ts-expect-error
        <Route
          key={route.path || route.id}
          index={route.index}
          element={route.element}
          path={route.path}>
          {route.children &&
            route.children.length > 0 &&
            mapRenderRoutes(route.children)}
        </Route>
      )
    })
  }

  const content = (
    <HelmetProvider context={helmetContext}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#157E6F',
            controlOutline: 'none'
          }
        }}>
        <Suspense fallback={<div />}>
          <Routes>
            {mapRenderRoutes(routes)}
            {/* {routes.map((r) => (
                <Route element={r.element} path={r.path} key={r.path}>
                  {r.children &&
                    r.children.length > 0 &&
                    r.children.map((child) => (
                      <Route
                        element={child.element}
                        path={child.path}
                        key={child.path}
                      />
                    ))}
                </Route>
              ))} */}
            <Route
              path="*"
              element={
                <Suspense>
                  <NotFound />
                </Suspense>
              }
            />
          </Routes>
        </Suspense>
        <NoSSR>
          <LoginModal />
        </NoSSR>
      </ConfigProvider>
    </HelmetProvider>
  )

  const IsomophicRouter = isServer ? (
    <StaticRouter location={location || ''}>{content}</StaticRouter>
  ) : (
    <BrowserRouter>
      <>{content}</>
    </BrowserRouter>
  )

  // 进入网站后获取用户信息判断是否已登录
  useEffect(() => {
    getCustomerInfo().then((res) => {
      if (res.code === 200) {
        store.dispatch(updateUserInfo(res.data))
      } else {
        store.dispatch(updateUserInfo({}))
      }
    })
    store.dispatch(fetchCommonData())
  }, [])

  return (
    <ErrorBoundary
      onReset={reset}
      fallbackRender={({ resetErrorBoundary, error }: any) => (
        <Suspense>
          <ErrorFound resetErrorBoundary={resetErrorBoundary} error={error} />
        </Suspense>
      )}>
      <Provider store={store} serverState={preloadedState || {}}>
        <QueryClientProvider client={queryClient}>
          {/* <ReactQueryDevtools initialIsOpen={false} /> */}
          <Hydrate state={dehydratedState}>{IsomophicRouter}</Hydrate>
        </QueryClientProvider>
      </Provider>
    </ErrorBoundary>
  )
}

export default App
