import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { request } from '@utils/request'

export interface LoginState {
  loginVisible: boolean
  callback?: Function
}

const initialState: LoginState = {
  loginVisible: false
}

export const LoginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    triggleLogin: (state, action: PayloadAction<boolean>) => {
      state.loginVisible = action.payload
    },
    success: (state, action: PayloadAction<Function>) => {
      state.callback = action.payload
    }
  }
})

const API_NAME = '/account'
// 检查邮箱状态
export const checkEmail = (params: any): any => {
  return request.post(`${API_NAME}/checkEmail`, params).then((res) => {
    return res.data
  })
}

// 用户注册
export const userRegister = (params: any): any => {
  return request.post(`${API_NAME}/userRegister`, params).then((res) => {
    return res
  })
}

// 用户登录
export const userLogin = (params: any): any => {
  return request.post(`${API_NAME}/userLogin`, params).then((res) => {
    return res
  })
}

// 忘记密码-发送邮箱
export const sendCustomerVerifEmail = (params: any): any => {
  return request
    .post(`customers/sendCustomerVerifEmail`, params)
    .then((res) => {
      return res
    })
}

// 用户退出
export const userLogout = (): any => {
  return request.get(`${API_NAME}/userLogout`).then((res) => {
    return res
  })
}

export const { triggleLogin, success } = LoginSlice.actions

export default LoginSlice.reducer
