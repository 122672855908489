import homeReducer from '@api/home/home/homeSlice'
import cartSlice from '@api/home/shopCart/cartSlice'
import appSlice from '@api/home/store/slice/appSlice'
import loginSlice from '@api/home/store/slice/loginSlice'
import pageDataSlice from '@api/home/store/slice/pageDataSlice'
import productsSlice from '@api/home/store/slice/productsSlice'
import searchInputSlice from '@api/home/store/slice/searchInputSlice'
import { configureStore } from '@reduxjs/toolkit'
import thunk from 'redux-thunk'
import { isDev } from '@utils'

const rootReducer = {
  app: appSlice,
  home: homeReducer,
  searchInput: searchInputSlice,
  products: productsSlice,
  shopcart: cartSlice,
  login: loginSlice,
  page: pageDataSlice
}

export const getReduxStore = (defaultState: { [x: string]: any }) => {
  return configureStore({
    reducer: rootReducer,
    middleware: [thunk],
    devTools: isDev,
    preloadedState: defaultState
  })
}

const initialState = getReduxStore({}).getState

export default getReduxStore

export type RootState = ReturnType<typeof initialState>
