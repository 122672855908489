import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import * as query from '../../../../../template/home/src/pages/Products/action'
import { fetchHomeDataV2 } from '@api/home/home/homeSlice'
import { isMobileProject } from '@utils/index'
interface productsState {
  banners: { [key: string]: any }
  data: any
  filter: {
    filterList: any[]
    orderSelect: any[]
  }
}

const initialState: productsState = {
  banners: {},
  data: {
    categoryName: '',
    productList: [],
    metaInfo: {},
    pageTotal: 0,
    productCount: 0,
    maxPrice: '',
    formatMaxPrice: '',
    navBread: []
  },
  filter: {
    filterList: [],
    orderSelect: []
  }
}

export const { fetchProducts, fetchFilter, fetchFlowProducts } = query

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // 商品卡片数据
    builder.addCase(
      fetchProducts.fulfilled,
      (
        state,
        action: PayloadAction<{ code: number; message: string; data: any }>
      ) => {
        const { code, message, data } = action.payload || {}
        if (code === 200) {
          if (isMobileProject && data.page > 1) {
            state.data.productList = [
              ...state.data.productList,
              ...data.productList
            ]
          } else {
            state.data = data
          }
        } else {
          console.error('类目页接口出错:' + message)
        }
      }
    )
    // 属性筛选数据
    builder.addCase(
      fetchFilter.fulfilled,
      (
        state,
        action: PayloadAction<{ code: number; message: string; data: any }>
      ) => {
        state.filter = action.payload?.data || {}
      }
    )
    // 活动商品卡片数据
    builder.addCase(
      fetchFlowProducts.fulfilled,
      (
        state,
        action: PayloadAction<{ code: number; message: string; data: any }>
      ) => {
        const { code, message, data } = action.payload || {}
        if (code === 200) {
          if (isMobileProject && data.page > 1) {
            state.data.productList = [
              ...state.data.productList,
              ...data.productList
            ]
          } else {
            state.data = data
          }
        } else {
          console.error(message)
        }
      }
    )
    // 获取 FD banner 数据
    builder.addCase(fetchHomeDataV2.fulfilled, (state, action) => {
      state.banners = action.payload.banners
    })
  }
})

export const {} = productsSlice.actions

export default productsSlice.reducer
